@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=52.5625em&large=64.0625em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: white;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  color: #333333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto; }
  .row::before, .row::after {
    display: table;
    content: ' '; }
  .row::after {
    clear: both; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row .row {
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
    @media print, screen and (min-width: 52.5625em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 64.0625em) {
      .row .row {
        margin-right: -1.25rem;
        margin-left: -1.25rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
    .row.expanded .row {
      margin-right: auto;
      margin-left: auto; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.gutter-small > .column, .row.gutter-small > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .row.gutter-medium > .column, .row.gutter-medium > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }

.column, .columns {
  width: 100%;
  float: left;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }
  @media print, screen and (min-width: 52.5625em) {
    .column, .columns {
      padding-right: 1.25rem;
      padding-left: 1.25rem; } }
  .column:last-child:not(:first-child), .columns:last-child:not(:first-child) {
    float: right; }
  .column.end:last-child:last-child, .end.columns:last-child:last-child {
    float: left; }

.column.row.row, .row.row.columns {
  float: none; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.small-1 {
  width: 8.33333%; }

.small-push-1 {
  position: relative;
  left: 8.33333%; }

.small-pull-1 {
  position: relative;
  left: -8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  width: 16.66667%; }

.small-push-2 {
  position: relative;
  left: 16.66667%; }

.small-pull-2 {
  position: relative;
  left: -16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  width: 25%; }

.small-push-3 {
  position: relative;
  left: 25%; }

.small-pull-3 {
  position: relative;
  left: -25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  width: 33.33333%; }

.small-push-4 {
  position: relative;
  left: 33.33333%; }

.small-pull-4 {
  position: relative;
  left: -33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  width: 41.66667%; }

.small-push-5 {
  position: relative;
  left: 41.66667%; }

.small-pull-5 {
  position: relative;
  left: -41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  width: 50%; }

.small-push-6 {
  position: relative;
  left: 50%; }

.small-pull-6 {
  position: relative;
  left: -50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  width: 58.33333%; }

.small-push-7 {
  position: relative;
  left: 58.33333%; }

.small-pull-7 {
  position: relative;
  left: -58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  width: 66.66667%; }

.small-push-8 {
  position: relative;
  left: 66.66667%; }

.small-pull-8 {
  position: relative;
  left: -66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  width: 75%; }

.small-push-9 {
  position: relative;
  left: 75%; }

.small-pull-9 {
  position: relative;
  left: -75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  width: 83.33333%; }

.small-push-10 {
  position: relative;
  left: 83.33333%; }

.small-pull-10 {
  position: relative;
  left: -83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  width: 91.66667%; }

.small-push-11 {
  position: relative;
  left: 91.66667%; }

.small-pull-11 {
  position: relative;
  left: -91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-up-1 > .column, .small-up-1 > .columns {
  float: left;
  width: 100%; }
  .small-up-1 > .column:nth-of-type(1n), .small-up-1 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-1 > .column:nth-of-type(1n+1), .small-up-1 > .columns:nth-of-type(1n+1) {
    clear: both; }
  .small-up-1 > .column:last-child, .small-up-1 > .columns:last-child {
    float: left; }

.small-up-2 > .column, .small-up-2 > .columns {
  float: left;
  width: 50%; }
  .small-up-2 > .column:nth-of-type(1n), .small-up-2 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-2 > .column:nth-of-type(2n+1), .small-up-2 > .columns:nth-of-type(2n+1) {
    clear: both; }
  .small-up-2 > .column:last-child, .small-up-2 > .columns:last-child {
    float: left; }

.small-up-3 > .column, .small-up-3 > .columns {
  float: left;
  width: 33.33333%; }
  .small-up-3 > .column:nth-of-type(1n), .small-up-3 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-3 > .column:nth-of-type(3n+1), .small-up-3 > .columns:nth-of-type(3n+1) {
    clear: both; }
  .small-up-3 > .column:last-child, .small-up-3 > .columns:last-child {
    float: left; }

.small-up-4 > .column, .small-up-4 > .columns {
  float: left;
  width: 25%; }
  .small-up-4 > .column:nth-of-type(1n), .small-up-4 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-4 > .column:nth-of-type(4n+1), .small-up-4 > .columns:nth-of-type(4n+1) {
    clear: both; }
  .small-up-4 > .column:last-child, .small-up-4 > .columns:last-child {
    float: left; }

.small-up-5 > .column, .small-up-5 > .columns {
  float: left;
  width: 20%; }
  .small-up-5 > .column:nth-of-type(1n), .small-up-5 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-5 > .column:nth-of-type(5n+1), .small-up-5 > .columns:nth-of-type(5n+1) {
    clear: both; }
  .small-up-5 > .column:last-child, .small-up-5 > .columns:last-child {
    float: left; }

.small-up-6 > .column, .small-up-6 > .columns {
  float: left;
  width: 16.66667%; }
  .small-up-6 > .column:nth-of-type(1n), .small-up-6 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-6 > .column:nth-of-type(6n+1), .small-up-6 > .columns:nth-of-type(6n+1) {
    clear: both; }
  .small-up-6 > .column:last-child, .small-up-6 > .columns:last-child {
    float: left; }

.small-up-7 > .column, .small-up-7 > .columns {
  float: left;
  width: 14.28571%; }
  .small-up-7 > .column:nth-of-type(1n), .small-up-7 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-7 > .column:nth-of-type(7n+1), .small-up-7 > .columns:nth-of-type(7n+1) {
    clear: both; }
  .small-up-7 > .column:last-child, .small-up-7 > .columns:last-child {
    float: left; }

.small-up-8 > .column, .small-up-8 > .columns {
  float: left;
  width: 12.5%; }
  .small-up-8 > .column:nth-of-type(1n), .small-up-8 > .columns:nth-of-type(1n) {
    clear: none; }
  .small-up-8 > .column:nth-of-type(8n+1), .small-up-8 > .columns:nth-of-type(8n+1) {
    clear: both; }
  .small-up-8 > .column:last-child, .small-up-8 > .columns:last-child {
    float: left; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-collapse .row {
  margin-right: 0;
  margin-left: 0; }

.expanded.row .small-collapse.row {
  margin-right: 0;
  margin-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.small-centered {
  margin-right: auto;
  margin-left: auto; }
  .small-centered, .small-centered:last-child:not(:first-child) {
    float: none;
    clear: both; }

.small-uncentered,
.small-push-0,
.small-pull-0 {
  position: static;
  float: left;
  margin-right: 0;
  margin-left: 0; }

@media print, screen and (min-width: 52.5625em) {
  .medium-1 {
    width: 8.33333%; }
  .medium-push-1 {
    position: relative;
    left: 8.33333%; }
  .medium-pull-1 {
    position: relative;
    left: -8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    width: 16.66667%; }
  .medium-push-2 {
    position: relative;
    left: 16.66667%; }
  .medium-pull-2 {
    position: relative;
    left: -16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    width: 25%; }
  .medium-push-3 {
    position: relative;
    left: 25%; }
  .medium-pull-3 {
    position: relative;
    left: -25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    width: 33.33333%; }
  .medium-push-4 {
    position: relative;
    left: 33.33333%; }
  .medium-pull-4 {
    position: relative;
    left: -33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    width: 41.66667%; }
  .medium-push-5 {
    position: relative;
    left: 41.66667%; }
  .medium-pull-5 {
    position: relative;
    left: -41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    width: 50%; }
  .medium-push-6 {
    position: relative;
    left: 50%; }
  .medium-pull-6 {
    position: relative;
    left: -50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    width: 58.33333%; }
  .medium-push-7 {
    position: relative;
    left: 58.33333%; }
  .medium-pull-7 {
    position: relative;
    left: -58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    width: 66.66667%; }
  .medium-push-8 {
    position: relative;
    left: 66.66667%; }
  .medium-pull-8 {
    position: relative;
    left: -66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    width: 75%; }
  .medium-push-9 {
    position: relative;
    left: 75%; }
  .medium-pull-9 {
    position: relative;
    left: -75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    width: 83.33333%; }
  .medium-push-10 {
    position: relative;
    left: 83.33333%; }
  .medium-pull-10 {
    position: relative;
    left: -83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    width: 91.66667%; }
  .medium-push-11 {
    position: relative;
    left: 91.66667%; }
  .medium-pull-11 {
    position: relative;
    left: -91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-up-1 > .column, .medium-up-1 > .columns {
    float: left;
    width: 100%; }
    .medium-up-1 > .column:nth-of-type(1n), .medium-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-1 > .column:nth-of-type(1n+1), .medium-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .medium-up-1 > .column:last-child, .medium-up-1 > .columns:last-child {
      float: left; }
  .medium-up-2 > .column, .medium-up-2 > .columns {
    float: left;
    width: 50%; }
    .medium-up-2 > .column:nth-of-type(1n), .medium-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-2 > .column:nth-of-type(2n+1), .medium-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .medium-up-2 > .column:last-child, .medium-up-2 > .columns:last-child {
      float: left; }
  .medium-up-3 > .column, .medium-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .medium-up-3 > .column:nth-of-type(1n), .medium-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-3 > .column:nth-of-type(3n+1), .medium-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .medium-up-3 > .column:last-child, .medium-up-3 > .columns:last-child {
      float: left; }
  .medium-up-4 > .column, .medium-up-4 > .columns {
    float: left;
    width: 25%; }
    .medium-up-4 > .column:nth-of-type(1n), .medium-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-4 > .column:nth-of-type(4n+1), .medium-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .medium-up-4 > .column:last-child, .medium-up-4 > .columns:last-child {
      float: left; }
  .medium-up-5 > .column, .medium-up-5 > .columns {
    float: left;
    width: 20%; }
    .medium-up-5 > .column:nth-of-type(1n), .medium-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-5 > .column:nth-of-type(5n+1), .medium-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .medium-up-5 > .column:last-child, .medium-up-5 > .columns:last-child {
      float: left; }
  .medium-up-6 > .column, .medium-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .medium-up-6 > .column:nth-of-type(1n), .medium-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-6 > .column:nth-of-type(6n+1), .medium-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .medium-up-6 > .column:last-child, .medium-up-6 > .columns:last-child {
      float: left; }
  .medium-up-7 > .column, .medium-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .medium-up-7 > .column:nth-of-type(1n), .medium-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-7 > .column:nth-of-type(7n+1), .medium-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .medium-up-7 > .column:last-child, .medium-up-7 > .columns:last-child {
      float: left; }
  .medium-up-8 > .column, .medium-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .medium-up-8 > .column:nth-of-type(1n), .medium-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .medium-up-8 > .column:nth-of-type(8n+1), .medium-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .medium-up-8 > .column:last-child, .medium-up-8 > .columns:last-child {
      float: left; }
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .medium-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .medium-centered {
    margin-right: auto;
    margin-left: auto; }
    .medium-centered, .medium-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .medium-uncentered,
  .medium-push-0,
  .medium-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

@media print, screen and (min-width: 64.0625em) {
  .large-1 {
    width: 8.33333%; }
  .large-push-1 {
    position: relative;
    left: 8.33333%; }
  .large-pull-1 {
    position: relative;
    left: -8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    width: 16.66667%; }
  .large-push-2 {
    position: relative;
    left: 16.66667%; }
  .large-pull-2 {
    position: relative;
    left: -16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    width: 25%; }
  .large-push-3 {
    position: relative;
    left: 25%; }
  .large-pull-3 {
    position: relative;
    left: -25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    width: 33.33333%; }
  .large-push-4 {
    position: relative;
    left: 33.33333%; }
  .large-pull-4 {
    position: relative;
    left: -33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    width: 41.66667%; }
  .large-push-5 {
    position: relative;
    left: 41.66667%; }
  .large-pull-5 {
    position: relative;
    left: -41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    width: 50%; }
  .large-push-6 {
    position: relative;
    left: 50%; }
  .large-pull-6 {
    position: relative;
    left: -50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    width: 58.33333%; }
  .large-push-7 {
    position: relative;
    left: 58.33333%; }
  .large-pull-7 {
    position: relative;
    left: -58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    width: 66.66667%; }
  .large-push-8 {
    position: relative;
    left: 66.66667%; }
  .large-pull-8 {
    position: relative;
    left: -66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    width: 75%; }
  .large-push-9 {
    position: relative;
    left: 75%; }
  .large-pull-9 {
    position: relative;
    left: -75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    width: 83.33333%; }
  .large-push-10 {
    position: relative;
    left: 83.33333%; }
  .large-pull-10 {
    position: relative;
    left: -83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    width: 91.66667%; }
  .large-push-11 {
    position: relative;
    left: 91.66667%; }
  .large-pull-11 {
    position: relative;
    left: -91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-up-1 > .column, .large-up-1 > .columns {
    float: left;
    width: 100%; }
    .large-up-1 > .column:nth-of-type(1n), .large-up-1 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-1 > .column:nth-of-type(1n+1), .large-up-1 > .columns:nth-of-type(1n+1) {
      clear: both; }
    .large-up-1 > .column:last-child, .large-up-1 > .columns:last-child {
      float: left; }
  .large-up-2 > .column, .large-up-2 > .columns {
    float: left;
    width: 50%; }
    .large-up-2 > .column:nth-of-type(1n), .large-up-2 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-2 > .column:nth-of-type(2n+1), .large-up-2 > .columns:nth-of-type(2n+1) {
      clear: both; }
    .large-up-2 > .column:last-child, .large-up-2 > .columns:last-child {
      float: left; }
  .large-up-3 > .column, .large-up-3 > .columns {
    float: left;
    width: 33.33333%; }
    .large-up-3 > .column:nth-of-type(1n), .large-up-3 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-3 > .column:nth-of-type(3n+1), .large-up-3 > .columns:nth-of-type(3n+1) {
      clear: both; }
    .large-up-3 > .column:last-child, .large-up-3 > .columns:last-child {
      float: left; }
  .large-up-4 > .column, .large-up-4 > .columns {
    float: left;
    width: 25%; }
    .large-up-4 > .column:nth-of-type(1n), .large-up-4 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-4 > .column:nth-of-type(4n+1), .large-up-4 > .columns:nth-of-type(4n+1) {
      clear: both; }
    .large-up-4 > .column:last-child, .large-up-4 > .columns:last-child {
      float: left; }
  .large-up-5 > .column, .large-up-5 > .columns {
    float: left;
    width: 20%; }
    .large-up-5 > .column:nth-of-type(1n), .large-up-5 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-5 > .column:nth-of-type(5n+1), .large-up-5 > .columns:nth-of-type(5n+1) {
      clear: both; }
    .large-up-5 > .column:last-child, .large-up-5 > .columns:last-child {
      float: left; }
  .large-up-6 > .column, .large-up-6 > .columns {
    float: left;
    width: 16.66667%; }
    .large-up-6 > .column:nth-of-type(1n), .large-up-6 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-6 > .column:nth-of-type(6n+1), .large-up-6 > .columns:nth-of-type(6n+1) {
      clear: both; }
    .large-up-6 > .column:last-child, .large-up-6 > .columns:last-child {
      float: left; }
  .large-up-7 > .column, .large-up-7 > .columns {
    float: left;
    width: 14.28571%; }
    .large-up-7 > .column:nth-of-type(1n), .large-up-7 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-7 > .column:nth-of-type(7n+1), .large-up-7 > .columns:nth-of-type(7n+1) {
      clear: both; }
    .large-up-7 > .column:last-child, .large-up-7 > .columns:last-child {
      float: left; }
  .large-up-8 > .column, .large-up-8 > .columns {
    float: left;
    width: 12.5%; }
    .large-up-8 > .column:nth-of-type(1n), .large-up-8 > .columns:nth-of-type(1n) {
      clear: none; }
    .large-up-8 > .column:nth-of-type(8n+1), .large-up-8 > .columns:nth-of-type(8n+1) {
      clear: both; }
    .large-up-8 > .column:last-child, .large-up-8 > .columns:last-child {
      float: left; }
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-collapse .row {
    margin-right: 0;
    margin-left: 0; }
  .expanded.row .large-collapse.row {
    margin-right: 0;
    margin-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.25rem;
    padding-left: 1.25rem; }
  .large-centered {
    margin-right: auto;
    margin-left: auto; }
    .large-centered, .large-centered:last-child:not(:first-child) {
      float: none;
      clear: both; }
  .large-uncentered,
  .large-push-0,
  .large-pull-0 {
    position: static;
    float: left;
    margin-right: 0;
    margin-left: 0; } }

.column-block {
  margin-bottom: 2.5rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 52.5625em) {
    .column-block {
      margin-bottom: 2.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #646363;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #e1e1e1; }

h1 {
  font-size: 1.5rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.1875rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.0625rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 52.5625em) {
  h1 {
    font-size: 3rem; }
  h2 {
    font-size: 2.5rem; }
  h3 {
    font-size: 1.9375rem; }
  h4 {
    font-size: 1.5625rem; }
  h5 {
    font-size: 1.25rem; }
  h6 {
    font-size: 1rem; } }

a {
  line-height: inherit;
  color: #344a71;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #2d4061; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 75rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #e1e1e1;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #e1e1e1; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #cccccc; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #cccccc; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #121211;
  color: #333333;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #e1e1e1;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #121211; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #121211; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.4;
  color: #cccccc; }

.lead {
  font-size: 125%;
  line-height: 1.6; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 52.5625em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64.0625em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #cccccc;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  .print-break-inside {
    page-break-inside: auto; } }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: #ffffff;
  box-shadow: inset 0 1px 2px rgba(18, 18, 17, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #121211;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 0 5px #e1e1e1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #e1e1e1; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #121211; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #121211; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #e1e1e1;
  background: #e6e6e6;
  color: #121211;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #e1e1e1; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: white; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-color: #ffffff;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #121211;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28204, 204, 204%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAMBJREFUeNrEllsOhCAMRVszC9IlzU7KCmVHTJsoMWYMUtpyv9BgbuXQB5ZSdgBYYY4ycgBivk8KYFsQMfMiTTBP4o3nUzCKzOabLJbLy2/g31evGkAginR4/ZegKH5qX3bJCscA3t0x3kgO5tQFyhhFf50xRqFLbyMUNJQzgyjGS/wgCpvKqkRBpuWrE4V9d+1E4dPUXqIg107SQOE/2DRQxMwTDygIInVDET9T3lCoj/6j/VCmGjZOl2lKpZ8AAwDQP7zIimDGFQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    box-shadow: 0 0 5px #e1e1e1;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #e3051b;
  background-color: #fce6e8; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #e3051b; }

.is-invalid-label {
  color: #e3051b; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #e3051b; }
  .form-error.is-visible {
    display: block; }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #344a71;
  color: #ffffff; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #2c3f60;
    color: #ffffff; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.75rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #344a71;
    color: #ffffff; }
    .button.primary:hover, .button.primary:focus {
      background-color: #2a3b5a;
      color: #ffffff; }
  .button.secondary {
    background-color: #d0d0d0;
    color: #121211; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #a6a6a6;
      color: #121211; }
  .button.success {
    background-color: #27a737;
    color: #121211; }
    .button.success:hover, .button.success:focus {
      background-color: #1f862c;
      color: #121211; }
  .button.warning {
    background-color: #fcc500;
    color: #121211; }
    .button.warning:hover, .button.warning:focus {
      background-color: #ca9e00;
      color: #121211; }
  .button.alert {
    background-color: #e3051b;
    color: #ffffff; }
    .button.alert:hover, .button.alert:focus {
      background-color: #b60416;
      color: #ffffff; }
  .button.hollow {
    border: 1px solid #344a71;
    color: #344a71; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #1a2539;
      color: #1a2539; }
    .button.hollow.primary {
      border: 1px solid #344a71;
      color: #344a71; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #1a2539;
        color: #1a2539; }
    .button.hollow.secondary {
      border: 1px solid #d0d0d0;
      color: #d0d0d0; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #686868;
        color: #686868; }
    .button.hollow.success {
      border: 1px solid #27a737;
      color: #27a737; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #14541c;
        color: #14541c; }
    .button.hollow.warning {
      border: 1px solid #fcc500;
      color: #fcc500; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #7e6300;
        color: #7e6300; }
    .button.hollow.alert {
      border: 1px solid #e3051b;
      color: #e3051b; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #72030e;
        color: #72030e; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #344a71;
      color: #ffffff; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #344a71;
        color: #ffffff; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #d0d0d0;
        color: #121211; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #27a737;
        color: #121211; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #fcc500;
        color: #121211; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #e3051b;
        color: #ffffff; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #ffffff transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 1em; }
  .button.dropdown.hollow::after {
    border-top-color: #344a71; }
  .button.dropdown.hollow.primary::after {
    border-top-color: #344a71; }
  .button.dropdown.hollow.secondary::after {
    border-top-color: #d0d0d0; }
  .button.dropdown.hollow.success::after {
    border-top-color: #27a737; }
  .button.dropdown.hollow.warning::after {
    border-top-color: #fcc500; }
  .button.dropdown.hollow.alert::after {
    border-top-color: #e3051b; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 52.5625em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64.0625em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #344a71;
    color: #ffffff; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #ffffff;
    box-shadow: 0 7px 0 #ffffff, 0 14px 0 #ffffff;
    content: ''; }
  .menu-icon:hover::after {
    background: #e1e1e1;
    box-shadow: 0 7px 0 #e1e1e1, 0 14px 0 #e1e1e1; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #121211;
    box-shadow: 0 7px 0 #121211, 0 14px 0 #121211;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #cccccc;
    box-shadow: 0 7px 0 #cccccc, 0 14px 0 #cccccc; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.8rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #344a71;
  color: #ffffff; }
  .label.primary {
    background: #344a71;
    color: #ffffff; }
  .label.secondary {
    background: #d0d0d0;
    color: #121211; }
  .label.success {
    background: #27a737;
    color: #121211; }
  .label.warning {
    background: #fcc500;
    color: #121211; }
  .label.alert {
    background: #e3051b;
    color: #ffffff; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 52.5625em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #121211; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #344a71;
    color: #ffffff;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #e1e1e1;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #121211; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f2f2f2;
    background-color: #ffffff; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f9f9f9;
    color: #333333; }
  table tfoot {
    background: #f2f2f2;
    color: #333333; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f2f2f2; }
  table.unstriped tbody {
    background-color: #ffffff; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f2f2f2;
      background-color: #ffffff; }

@media screen and (max-width: 64em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f4f4f4; }

table.hover tfoot tr:hover {
  background-color: #ededed; }

table.hover tbody tr:hover {
  background-color: #fafafa; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ededed; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 52.5625em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 64em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 52.5em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 52.5625em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 52.5625em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 52.5em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 52.5625em) and (max-width: 64em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 52.5em), screen and (min-width: 64.0625em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64.0625em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 64em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64.0625em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 64em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans-v34-latin-700.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans-v34-latin-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  src: url("../fonts/open-sans-v34-latin-300.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

.item {
  height: 300px;
  position: relative; }
  .item .owl-text {
    color: #ffffff;
    text-align: center;
    position: absolute;
    width: 230px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .item .owl-text .sliderSubtitel, .item .owl-text .sliderTitel {
      font-weight: 700;
      color: #ffffff;
      text-shadow: 2px 2px 17px #333333; }
  .item .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(4, 4, 4, 0.14); }

.buehne-slider {
  position: relative; }
  .buehne-slider .owl-controls .owl-nav {
    color: #ffffff;
    font-size: 3.125rem; }
    .buehne-slider .owl-controls .owl-nav .owl-prev {
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); }
    .buehne-slider .owl-controls .owl-nav .owl-next {
      right: 15px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%); }

ul.primary {
  border-bottom: none;
  text-align: left;
  white-space: normal;
  padding: 0; }
  ul.primary li {
    display: inline-block;
    background: #344a71;
    margin: 5px;
    padding: 7px 15px;
    transition: all 0.6s; }
    ul.primary li:hover {
      background-color: #e3051b; }
    ul.primary li a {
      border: none;
      color: #ffffff;
      background: none;
      transition: all 0.6s; }
      ul.primary li a:hover {
        background-color: transparent; }
    ul.primary li.active {
      background: #27a737; }
      ul.primary li.active:hover {
        background-color: #344a71; }
      ul.primary li.active a {
        border: none;
        background: #344a71;
        background: none; }
        ul.primary li.active a:hover {
          background-color: transparent; }

.profile, #user-login {
  max-width: 75rem;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.25rem;
  padding-left: 1.25rem; }

.media-bulk-upload-multiedit-form {
  padding: 30px; }

div.status {
  background-color: #f6f6f6;
  border: none; }

.l-footer {
  background: #333333;
  padding: 30px 0;
  position: relative;
  overflow-x: hidden; }
  .l-footer .footer-logo .logo-link h5 {
    color: #cccccc; }
  .l-footer .footer-logo .logo-link .logo-box {
    display: inline-block;
    width: 150px;
    height: 110px;
    background-image: url("/sites/all/themes/stoeckchenreisen/images/logo-to-right-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.8s; }
  .l-footer .footer-logo .logo-link:hover .logo-box {
    background-image: url("/sites/all/themes/stoeckchenreisen/images/logo-to-right.svg"); }
  .l-footer .footer-flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-end;
    align-items: flex-end; }
    .l-footer .footer-flex-container .flex-item {
      width: 100%; }
      .l-footer .footer-flex-container .flex-item.first {
        -webkit-order: -1;
        order: -1; }
      .l-footer .footer-flex-container .flex-item.second {
        -webkit-order: -1;
        order: -1; }
  .l-footer address {
    font-style: normal;
    color: #cccccc;
    margin-bottom: 5px; }
  .l-footer span {
    color: #cccccc; }
    .l-footer span:before {
      transition: all 0.5s;
      color: #cccccc;
      font-size: 22px;
      vertical-align: middle;
      line-height: 1.5; }
  .l-footer a {
    color: #cccccc; }
    .l-footer a:hover {
      color: #ffffff; }
      .l-footer a:hover span:before {
        color: #ffffff; }
  .l-footer ul.menu {
    column-count: 2;
    max-width: 300px;
    margin: 10px auto 5px auto; }
    .l-footer ul.menu li {
      display: block; }
      .l-footer ul.menu li a {
        color: #cccccc;
        padding: 0.2rem 5px; }
        .l-footer ul.menu li a:hover {
          color: #ffffff; }
  .l-footer .icon-box {
    position: absolute;
    height: 45px;
    width: 45px;
    padding: 10px;
    border: solid 1px #cccccc;
    vertical-align: middle;
    right: -2px;
    top: 20px;
    transform: translateX(100%);
    transition: transform 0.5s; }
    .l-footer .icon-box:nth-of-type(1) {
      top: 80px;
      transition: transform 1s; }
    .l-footer .icon-box.open {
      transform: translateX(0); }
    .l-footer .icon-box span:before {
      position: absolute;
      transition: all 0.5s;
      color: #cccccc;
      font-size: 18px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
    .l-footer .icon-box:hover {
      background: #344a71; }
      .l-footer .icon-box:hover span:before {
        color: #ffffff; }

.footer-bottom {
  padding: 5px;
  font-size: 0.875rem;
  background: #344a71;
  color: #ffffff; }

@media screen and (min-width: 541px) {
  .l-footer {
    font-size: 18px; } }

.form-anfrage {
  background: #344a71; }

.l-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 450;
  transition: all 0.6s; }
  .l-header.swipeTop {
    transform: translate(0, -37px); }
  .l-header .top-header {
    padding: 5px 0;
    background-color: #333333;
    color: #cccccc; }
    .l-header .top-header .top-header-kontakt {
      display: inline-block;
      float: left; }
      .l-header .top-header .top-header-kontakt:last-child {
        float: right; }
    .l-header .top-header a {
      color: #cccccc;
      font-size: 0.875rem; }
    .l-header .top-header span:before {
      font-size: 1.0625rem;
      vertical-align: middle; }
  .l-header .top-bar {
    background-color: #344a71;
    padding: 8px 0; }
    .l-header .top-bar .logo-top {
      width: 70px;
      display: inline-block;
      margin-right: 10px; }
    .l-header .top-bar .logo-text {
      display: inline-block;
      color: #ffffff;
      font-size: 1.0625rem; }
    .l-header .top-bar ul {
      background: none; }
    .l-header .top-bar .mobile-menu {
      width: auto;
      position: fixed;
      top: 42px;
      right: 15px;
      z-index: 500; }
      .l-header .top-bar .mobile-menu .click-menu {
        position: relative;
        cursor: pointer; }
        .l-header .top-bar .mobile-menu .click-menu .menu {
          color: white;
          display: inline-block; }
        .l-header .top-bar .mobile-menu .click-menu .menu-button {
          display: inline-block;
          width: 40px;
          height: 30px;
          top: 10px;
          left: 10px;
          transition: all 0.6s;
          position: relative;
          padding-bottom: 5px; }
          .l-header .top-bar .mobile-menu .click-menu .menu-button .menue-icon {
            background: #ffffff;
            width: 25px;
            height: 2px;
            transition: transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
            position: absolute;
            left: 4px;
            top: 11px; }
            .l-header .top-bar .mobile-menu .click-menu .menu-button .menue-icon:nth-child(2) {
              top: 3px; }
            .l-header .top-bar .mobile-menu .click-menu .menu-button .menue-icon:nth-child(3) {
              top: 19px; }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.open .menue-icon:nth-child(1) {
            transform: scale(1); }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.open .menue-icon:nth-child(2) {
            transform: translate(7px, 4px) rotate(40deg) scale(0.5, 1); }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.open .menue-icon:nth-child(3) {
            transform: translate(7px, -4px) rotate(-40deg) scale(0.5, 1); }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.white .menue-icon {
            background: white; }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.swipeTop {
            transform: matrix(1, 0, 0, 1, 0, -35); }
          .l-header .top-bar .mobile-menu .click-menu .menu-button.open {
            transform: matrix(1, 0, 0, 1, 0, 0); }

.menu-overlay {
  padding: 130px 30px 30px 30px;
  z-index: 400;
  background-color: #121211;
  position: fixed;
  height: 100%;
  width: 230px;
  top: 0;
  right: 0;
  overflow-y: auto;
  transition: all 0.6s;
  transform: translateX(100%); }
  .menu-overlay.open {
    transform: translateX(0); }
  .menu-overlay .menu {
    top: 0;
    left: 0;
    display: block;
    width: 100%; }
    .menu-overlay .menu .expanded {
      position: relative; }
      .menu-overlay .menu .expanded .icon-arrow_right {
        color: #ffffff;
        position: absolute;
        display: inline-block;
        font-size: 20px;
        font-weight: 700;
        width: 40px;
        height: 40px;
        vertical-align: middle;
        padding: 10px 10px 10px 15px;
        top: 8px;
        right: 0;
        cursor: pointer;
        transition: all 600ms cubic-bezier(0.23, 1, 0.32, 1); }
        .menu-overlay .menu .expanded .icon-arrow_right.open {
          transform: rotate(90deg); }
        .menu-overlay .menu .expanded .icon-arrow_right:hover {
          color: #fcc500;
          border-color: #fcc500; }
    .menu-overlay .menu li {
      width: 100%;
      float: none;
      display: block;
      transition: transform 600ms cubic-bezier(0.23, 1, 0.32, 1);
      transform-origin: 0, 0;
      transform: matrix(1, 0, 0, 1, 250, 0); }
      .menu-overlay .menu li.active-trail .icon-arrow_right {
        color: #fcc500;
        border-color: #fcc500; }
      .menu-overlay .menu li:hover .icon-arrow_right {
        color: #fcc500;
        border-color: #fcc500; }
      .menu-overlay .menu li a {
        color: white;
        font-size: 25px;
        padding: 0.7rem 0;
        transition: all 0.5s; }
        .menu-overlay .menu li a.active {
          color: #fcc500; }
        .menu-overlay .menu li a:hover {
          color: #fcc500; }
      .menu-overlay .menu li.open {
        transform: matrix(1, 0, 0, 1, 0, 0); }
      .menu-overlay .menu li .menu {
        display: none; }
        .menu-overlay .menu li .menu li {
          transform: matrix(1, 0, 0, 1, 0, 0); }
          .menu-overlay .menu li .menu li a {
            font-size: 18px; }
  .menu-overlay .nolink {
    font-size: 25px;
    padding: 0.7rem 0; }

li .nolink {
  color: #ffffff;
  transition: all 0.5s; }

li:hover .nolink {
  color: #fcc500; }

@media screen and (min-height: 320px) {
  body {
    padding-top: 95px; }
  .l-header .top-bar .logo-top {
    width: 70px; }
  .l-header .top-bar .logo-text {
    font-size: 1.0625rem; }
  .l-header .top-bar .mobile-menu {
    top: 42px; } }

@media screen and (min-height: 400px) and (min-width: 414px) {
  body {
    padding-top: 122px; }
  .l-header .top-bar .logo-top {
    width: 100px; }
  .l-header .top-bar .logo-text {
    font-size: 1.125rem; }
  .l-header .top-bar .mobile-menu {
    top: 52px; } }

.paragraph-slider {
  padding: 0 0 50px 0; }
  .paragraph-slider .text-wrapper {
    margin: 30px auto 40px auto;
    max-width: 750px; }
  .paragraph-slider .slider-wrapper {
    position: relative;
    margin-top: 25px; }
    .paragraph-slider .slider-wrapper .slick-list {
      margin-left: -20px; }
      .paragraph-slider .slider-wrapper .slick-list .slick-slide {
        margin-left: 20px; }
    .paragraph-slider .slider-wrapper .image-wrapper {
      position: relative; }
      .paragraph-slider .slider-wrapper .image-wrapper .untertitel {
        position: absolute;
        left: 0;
        bottom: -100%;
        color: #ffffff;
        width: 100%;
        padding: 12px 35px 15px;
        transition: all 0.5s;
        overflow: hidden; }
        .paragraph-slider .slider-wrapper .image-wrapper .untertitel .blue-bg {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #005FB6;
          mix-blend-mode: multiply;
          z-index: 1; }
        .paragraph-slider .slider-wrapper .image-wrapper .untertitel .text {
          text-align: center;
          position: relative;
          z-index: 2;
          font-size: 14px;
          word-wrap: break-word;
          min-height: 100%; }
          .paragraph-slider .slider-wrapper .image-wrapper .untertitel .text h3 {
            font-size: 17px;
            color: #ffffff; }
      .paragraph-slider .slider-wrapper .image-wrapper:hover .untertitel {
        top: auto;
        bottom: 0; }
    .paragraph-slider .slider-wrapper .arrowWrapper {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translate(0, -50%);
      pointer-events: none; }
      .paragraph-slider .slider-wrapper .arrowWrapper span {
        color: #ffffff;
        font-size: 30px;
        padding: 15px;
        font-weight: 900;
        text-shadow: 0 3px 20px black;
        pointer-events: auto;
        background: #b3b3b3a3;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        text-align: center;
        cursor: pointer; }
      .paragraph-slider .slider-wrapper .arrowWrapper .icon-arrow_right {
        float: right;
        margin-right: 10px; }
      .paragraph-slider .slider-wrapper .arrowWrapper .icon-arrow_left {
        float: left;
        margin-left: 10px; }
  .paragraph-slider.has_bg {
    padding: 30px 0 50px 0; }
    .paragraph-slider.has_bg .section-heading, .paragraph-slider.has_bg .text-wrapper {
      color: white; }

@media screen and (min-width: 681px) {
  .paragraph-slider .slider-wrapper.centered_item .slick-list {
    margin-right: -1.25rem;
    margin-left: -1.25rem; }
  .paragraph-slider .slider-wrapper.centered_item .slick-slide, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] {
    position: relative;
    background-color: #717171;
    margin: 0 -50px;
    transition: transform 0.5s;
    transform: scale(0.7, 0.7);
    z-index: -1; }
    .paragraph-slider .slider-wrapper.centered_item .slick-slide img, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"] img {
      opacity: 0.7;
      filter: grayscale(100%); }
    .paragraph-slider .slider-wrapper.centered_item .slick-slide:hover .untertitel, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not(.slick-cloned) ~ .slick-cloned[aria-hidden="true"]:hover .untertitel {
      bottom: -100%; }
  .paragraph-slider .slider-wrapper.centered_item .slick-center, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] {
    transform: scale(1, 1);
    z-index: 10; }
    .paragraph-slider .slider-wrapper.centered_item .slick-center img, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"] img {
      opacity: 1;
      filter: grayscale(0); }
    .paragraph-slider .slider-wrapper.centered_item .slick-center:hover .untertitel, .paragraph-slider .slider-wrapper.centered_item .slick-slide[aria-hidden="true"]:not([tabindex="-1"]) + .slick-cloned[aria-hidden="true"]:hover .untertitel {
      top: auto;
      bottom: 0; } }

.fahrzeug-content {
  padding-bottom: 50px; }
  .fahrzeug-content .flex-container {
    background-color: #f6f6f6; }
    .fahrzeug-content .flex-container .fahrzeugbilder, .fahrzeug-content .flex-container .fahrzeugbeschreibung {
      padding: 15px 15px;
      position: relative; }
    .fahrzeug-content .flex-container .click-me {
      position: absolute;
      left: 50%;
      bottom: -10px;
      font-size: 10px;
      z-index: 20;
      transform: translateX(-50%); }
      .fahrzeug-content .flex-container .click-me .click-hand {
        font-size: 30px;
        margin-top: -20px; }
  .fahrzeug-content .clickSlidedown {
    text-align: center;
    background-color: #f6f6f6;
    position: relative; }
    .fahrzeug-content .clickSlidedown .openFormular {
      cursor: pointer;
      color: #3465bb;
      padding-bottom: 6px;
      transition: all 0.5s; }
      .fahrzeug-content .clickSlidedown .openFormular .icon-arrow_down {
        font-size: 40px;
        position: absolute;
        bottom: -44px;
        z-index: 10;
        left: 50%;
        transform: translate(-50%, -50%); }
      .fahrzeug-content .clickSlidedown .openFormular .arrowDown {
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #f6f6f6; }
    .fahrzeug-content .clickSlidedown:hover .openFormular {
      color: #e3051b; }

.node-partner {
  padding-top: 50px; }
  .node-partner .row-referenz {
    max-width: 1100px; }
    .node-partner .row-referenz .referenzen {
      padding: 30px 0; }
      .node-partner .row-referenz .referenzen .ref-content {
        position: relative;
        padding: 15px;
        width: 220px;
        height: 150px;
        cursor: pointer; }
        .node-partner .row-referenz .referenzen .ref-content .referenz-box {
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
          transition: all 0.6s;
          filter: grayscale(100%);
          opacity: 0.7; }
        .node-partner .row-referenz .referenzen .ref-content:hover .referenz-box {
          filter: grayscale(0);
          opacity: 1; }

.field-name-field-reiseart {
  font-size: 14px; }

.slider-reise {
  margin: 20px 0 15px 0; }
  .slider-reise .item-reise {
    height: 350px;
    position: relative;
    width: 100%; }

.bild-reise {
  height: 350px;
  position: relative;
  width: 100%;
  margin-bottom: 15px; }

.termin .field-reiseart {
  color: #ffffff;
  font-size: 14px; }

.panel {
  background-color: #f6f6f6;
  width: 100%;
  padding: 20px 10px 25px;
  margin-bottom: 40px;
  font-size: 16px; }
  .panel .panelHeading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px; }
  .panel .textLine {
    padding-left: 30px;
    font-size: 16px;
    margin: 5px; }
    .panel .textLine .icon-reise {
      font-size: 18px;
      position: absolute;
      left: 0;
      top: 2px; }
  .panel .Buchung {
    color: #27a737; }
  .panel .Ausgebucht {
    color: #e3051b; }
  .panel .preis {
    font-size: 20px;
    font-weight: 700;
    float: right;
    color: #e3051b; }
  .panel .label-preis {
    vertical-align: middle; }

.formular {
  display: none; }

@media screen and (min-width: 768px) {
  .slider-reise {
    margin: 20px 0 30px 0; }
    .slider-reise .item-reise {
      height: 400px; }
  .bild-reise {
    height: 400px;
    margin-bottom: 20px; } }

.view-display-id-empfehlung .node-reise .teaser-reise {
  margin: 20px auto; }

.no-slider-empfehlung .node-reise .teaser-reise {
  margin: 30px 0px; }

.view-reisen .view-content {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }

.teaser-reise {
  width: 310px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #d0d0d0;
  text-align: left; }
  .teaser-reise .teaserBild {
    width: 290px;
    height: 180px;
    position: relative; }
  .teaser-reise .teaser-text {
    padding: 0 5px; }
  .teaser-reise .teaser-header {
    color: #333333;
    margin: 3px 0;
    font-weight: 700;
    height: 50px;
    font-size: 1.125rem; }
  .teaser-reise .teaser-reiseart {
    margin-top: 5px; }
    .teaser-reise .teaser-reiseart .icon-bus {
      margin-right: 6px; }
  .teaser-reise .teaser-description {
    font-size: 0.875rem;
    height: 68px; }
  .teaser-reise .teaser-termin {
    font-size: 0.875rem;
    width: 70%;
    line-height: 2.1; }
  .teaser-reise .teaser-preis {
    text-align: right;
    font-size: 1.25rem;
    width: 30%;
    color: #e3051b;
    font-weight: 700; }
    .teaser-reise .teaser-preis span {
      color: #333333;
      font-size: 0.875rem;
      font-weight: 400; }

.owl-carousel .owl-controls .owl-dots {
  position: absolute;
  bottom: -45px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%; }
  .owl-carousel .owl-controls .owl-dots .owl-dot {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: inline-block;
    margin: 5px;
    background-color: #d0d0d0; }
    .owl-carousel .owl-controls .owl-dots .owl-dot.active {
      background-color: #333333; }

@media screen and (min-width: 541px) {
  .teaser-reise {
    margin: 10px 10px; }
  .no-slider-empfehlung .node-reise .teaser-reise {
    margin: 10px 10px; } }

.node-startseite .section-werbung {
  background-color: #344a71;
  padding: 40px 0 0; }

.node-werbung {
  vertical-align: middle;
  width: 100%;
  float: left; }
  .node-werbung .content {
    margin-bottom: 40px; }
    .node-werbung .content .gutKat-box {
      text-align: center;
      height: 200px;
      width: 100%;
      position: relative;
      padding: 10px; }
      .node-werbung .content .gutKat-box .gutKat-titel {
        color: #ffffff;
        font-size: 25px;
        font-weight: 700;
        text-shadow: 3px 2px 4px #121211; }
      .node-werbung .content .gutKat-box .button-gutKat {
        line-height: 22px;
        position: absolute;
        bottom: 10px;
        left: 10px; }

.inline-block {
  display: inline-block; }

.color-white {
  color: #ffffff; }

.clear {
  clear: both; }

.position-relative {
  position: relative; }

.show-for-medium-up {
  display: none !important; }

.show-for-medium-down {
  display: block !important; }

.show-for-medium-down-as-inline-block {
  display: inline-block !important; }

.show-for-medium-up-as-inline-block {
  display: none !important; }

a {
  transition: all 0.5s;
  color: #e3051b; }
  a:hover {
    color: #27a737; }

span.rot {
  color: #e3051b !important; }

span.grau {
  color: #d0d0d0 !important; }

span.gruen {
  color: #27a737 !important; }

span.gelb {
  color: #fcc500 !important; }

span.blau {
  color: #3465bb !important; }

span.violett {
  color: #7c6eb0 !important; }

span.schwarz {
  color: #333333 !important; }

.line {
  height: 1px;
  width: 100%;
  background-color: #333333;
  margin: 25px 0; }
  .line.subline {
    width: 85px;
    vertical-align: middle;
    margin: 0 10px;
    background-color: #d0d0d0; }
  .line.teaser-line {
    margin: 5px 0;
    background-color: #d0d0d0; }

.button-box {
  margin: 40px 0 30px 0; }

article {
  padding: 0 0 20px 0; }

html, body {
  height: 100%; }

body {
  padding-top: 95px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  overflow-x: hidden; }

.section-heading {
  font-size: 1.25rem;
  font-weight: 700; }

.ButtonBorder {
  background: #344a71;
  text-transform: uppercase;
  color: #ffffff;
  padding: 7px 22px;
  line-height: 34px;
  border: solid 1px #ffffff;
  font-weight: 700;
  font-size: 0.9375rem;
  transition: all 0.5s;
  cursor: pointer; }
  .ButtonBorder:hover {
    background: #e3051b;
    color: #ffffff; }
  .ButtonBorder:focus {
    color: #ffffff; }
  .ButtonBorder.teaserButton {
    background: #ffffff;
    text-transform: uppercase;
    color: #333333;
    padding: 7px 22px;
    line-height: 34px;
    border: solid 1px #333333;
    font-weight: 700;
    font-size: 0.9375rem;
    transition: all 0.5s;
    cursor: pointer;
    margin: 10px 0;
    line-height: 22px; }
    .ButtonBorder.teaserButton:hover {
      background: #344a71;
      color: #333333; }
    .ButtonBorder.teaserButton:focus {
      color: #333333; }
    .ButtonBorder.teaserButton:hover {
      color: #ffffff; }

.top {
  position: fixed;
  bottom: -60px;
  right: 30px;
  z-index: 300;
  border-radius: 50%;
  border: 2px solid white;
  background-color: #344a71;
  font-size: 30px;
  width: 50px;
  height: 50px;
  transition: transform 500ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 0 35px 0 rgba(0, 0, 0, 0.25); }
  .top .line-top {
    position: absolute;
    top: 24px;
    left: 19px;
    width: 13px;
    height: 3px;
    background-color: #ffffff;
    transform: rotate(45deg);
    transform-origin: 91%; }
    .top .line-top:last-of-type {
      transform: rotate(-45deg);
      transform-origin: 10%;
      left: 15px; }
  .top.active {
    transform: translate(0, -90px); }

#map {
  width: 100%;
  height: 400px; }

.animatedDiv {
  display: none; }

.section {
  padding: 30px 0 0 0; }
  .section.filter {
    padding: 0; }

.dsgvo-info {
  padding-bottom: 30px;
  display: inline-block; }

.form-anfrage, .view-id-reisen {
  padding-bottom: 30px; }

.node-startseite .paragraph-text-auf-startseite {
  background: #f6f6f6;
  padding: 30px 0; }
  .node-startseite .paragraph-text-auf-startseite article {
    padding: 0; }

.animated.duration-0-5s {
  animation-duration: 0.5s; }

.animated.delay-0-5 {
  animation-delay: 0.5s; }

.animated.duration-1s {
  animation-duration: 1s; }

.animated.delay-1 {
  animation-delay: 1s; }

.animated.duration-1-5s {
  animation-duration: 1.5s; }

.animated.delay-1-5 {
  animation-delay: 1.5s; }

.animated.duration-2s {
  animation-duration: 2s; }

.animated.delay-2 {
  animation-delay: 2s; }

.startbild {
  height: 250px;
  width: 100%;
  position: relative; }
  .startbild .titelBox {
    width: 100%;
    padding: 20px 0;
    left: 0;
    bottom: 0;
    position: absolute;
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.02) 0%, rgba(0, 0, 0, 0.5) 100%); }

.titelStartbild {
  color: #ffffff;
  font-weight: 700; }
  .titelStartbild.black {
    color: #646363;
    padding-top: 30px; }

.text-wrapper {
  margin: 30px 0 50px; }

.form-wrapper {
  margin-bottom: 30px; }

.textForm-wrapper {
  margin-bottom: 40px; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'], textarea, select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #d0d0d0;
  color: #333333;
  border-radius: 0;
  box-shadow: none;
  background: white;
  box-sizing: inherit; }
  [type='text']::-webkit-input-placeholder, [type='password']::-webkit-input-placeholder, [type='date']::-webkit-input-placeholder, [type='datetime']::-webkit-input-placeholder, [type='datetime-local']::-webkit-input-placeholder, [type='month']::-webkit-input-placeholder, [type='week']::-webkit-input-placeholder, [type='email']::-webkit-input-placeholder, [type='number']::-webkit-input-placeholder, [type='search']::-webkit-input-placeholder, [type='tel']::-webkit-input-placeholder, [type='time']::-webkit-input-placeholder, [type='url']::-webkit-input-placeholder, [type='color']::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #333333;
    font-size: 1.1875rem; }
  [type='text']::-moz-placeholder, [type='password']::-moz-placeholder, [type='date']::-moz-placeholder, [type='datetime']::-moz-placeholder, [type='datetime-local']::-moz-placeholder, [type='month']::-moz-placeholder, [type='week']::-moz-placeholder, [type='email']::-moz-placeholder, [type='number']::-moz-placeholder, [type='search']::-moz-placeholder, [type='tel']::-moz-placeholder, [type='time']::-moz-placeholder, [type='url']::-moz-placeholder, [type='color']::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
    /* Firefox 19+ */
    color: #333333;
    font-size: 1.1875rem; }
  [type='text']:-ms-input-placeholder, [type='password']:-ms-input-placeholder, [type='date']:-ms-input-placeholder, [type='datetime']:-ms-input-placeholder, [type='datetime-local']:-ms-input-placeholder, [type='month']:-ms-input-placeholder, [type='week']:-ms-input-placeholder, [type='email']:-ms-input-placeholder, [type='number']:-ms-input-placeholder, [type='search']:-ms-input-placeholder, [type='tel']:-ms-input-placeholder, [type='time']:-ms-input-placeholder, [type='url']:-ms-input-placeholder, [type='color']:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
    /* IE 10+ */
    color: #333333;
    font-size: 1.1875rem; }
  [type='text']:-moz-placeholder, [type='password']:-moz-placeholder, [type='date']:-moz-placeholder, [type='datetime']:-moz-placeholder, [type='datetime-local']:-moz-placeholder, [type='month']:-moz-placeholder, [type='week']:-moz-placeholder, [type='email']:-moz-placeholder, [type='number']:-moz-placeholder, [type='search']:-moz-placeholder, [type='tel']:-moz-placeholder, [type='time']:-moz-placeholder, [type='url']:-moz-placeholder, [type='color']:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder {
    /* Firefox 18- */
    color: #333333;
    font-size: 1.1875rem; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus, textarea:focus, select:focus {
    border: 1px solid #333333; }

.webform-component-textarea .grippie {
  display: none; }

[type='checkbox'] {
  margin: 0; }

.reiseruecktritt {
  margin-top: 0; }

.form-actions .form-submit {
  background: #344a71;
  text-transform: uppercase;
  color: white;
  padding: 7px 22px;
  line-height: 22px;
  border: solid 1px #ffffff;
  font-weight: 700;
  font-size: 0.9375rem;
  transition: all 0.5s; }
  .form-actions .form-submit:hover {
    background: #e3051b;
    color: #ffffff; }
  .form-actions .form-submit:focus {
    color: #ffffff; }

.form-actions:after {
  content: '* Pflichtangaben';
  font-size: 10px;
  line-height: 1.5;
  display: block; }

.header-reiseart {
  font-size: 1.875rem;
  font-weight: 700; }

#views-exposed-form-reisen-alle-reisen .container-inline-date .form-item input, #views-exposed-form-reisen-alle-reisen .container-inline-date .form-item select, #views-exposed-form-reisen-alle-reisen .container-inline-date .form-item option {
  margin-right: 0; }

#views-exposed-form-reisen-alle-reisen .container-inline-date > .form-item {
  margin-right: 0; }

#views-exposed-form-reisen-alle-reisen .views-exposed-widgets {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap; }
  #views-exposed-form-reisen-alle-reisen .views-exposed-widgets .views-exposed-widget {
    text-align: left;
    float: none; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets .views-exposed-widget.views-submit-button {
      padding: 0; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets .views-exposed-widget .description {
      display: none; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets .views-exposed-widget select {
      width: 187px;
      -webkit-appearance: none;
      -webkit-border-radius: 0; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets .views-exposed-widget label {
      font-weight: 100; }
  #views-exposed-form-reisen-alle-reisen .views-exposed-widgets #edit-reset {
    width: 187px;
    margin-top: 25px;
    background: #ffffff;
    text-transform: uppercase;
    color: #333333;
    padding: 7px 22px;
    line-height: 23px;
    border: solid 1px #d0d0d0;
    font-weight: 700;
    font-size: 0.9375rem;
    transition: all 0.5s;
    cursor: pointer; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets #edit-reset:hover {
      background: #344a71;
      color: #ffffff; }
    #views-exposed-form-reisen-alle-reisen .views-exposed-widgets #edit-reset:focus {
      color: #ffffff; }

.header-search {
  color: #ffffff;
  text-align: center; }

#views-exposed-form-reisen-suche-startseite .container-inline-date .form-item input, #views-exposed-form-reisen-suche-startseite .container-inline-date .form-item select, #views-exposed-form-reisen-suche-startseite .container-inline-date .form-item option {
  margin-right: 0; }

#views-exposed-form-reisen-suche-startseite .container-inline-date > .form-item {
  margin-right: 0; }

#views-exposed-form-reisen-suche-startseite .views-exposed-widgets {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 0; }
  #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget {
    text-align: left;
    float: none; }
    #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget.views-submit-button {
      padding: 0 1rem 0 0; }
      #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget.views-submit-button .form-submit {
        width: 187px;
        margin-top: 34px;
        background: #344a71;
        text-transform: uppercase;
        color: #ffffff;
        padding: 7px 22px;
        line-height: 23px;
        border: solid 1px #ffffff;
        font-weight: 700;
        font-size: 0.9375rem;
        transition: all 0.5s;
        cursor: pointer; }
        #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget.views-submit-button .form-submit:hover {
          background: #e3051b; }
        #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget.views-submit-button .form-submit:focus {
          color: #ffffff; }
    #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget [type='text'], #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget select {
      box-sizing: border-box;
      width: 187px;
      border: 1px solid #ffffff;
      background-color: #344a71;
      box-shadow: none;
      color: #ffffff; }
    #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget .description {
      display: none; }
    #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget select {
      width: 187px;
      -webkit-appearance: none;
      -webkit-border-radius: 0; }
    #views-exposed-form-reisen-suche-startseite .views-exposed-widgets .views-exposed-widget label {
      font-weight: 100;
      color: #ffffff; }

.label-dateBox {
  text-align: left;
  font-size: 12px; }

.dateBox {
  width: 100%;
  float: left; }
  .dateBox .dateTimeField {
    margin: 0;
    width: 50%;
    float: left; }
    .dateBox .dateTimeField:first-child {
      padding-right: 7px; }
    .dateBox .dateTimeField:last-child {
      padding-left: 7px; }
  .dateBox .datePicker.form-text, .dateBox .timePicker.form-text {
    margin-bottom: 0; }

.bis {
  text-align: center;
  float: left;
  width: 100%;
  font-size: 18px;
  padding: 8px;
  line-height: 22px; }

.webform-container-inline.webform-datepicker div.form-item.form-type-select {
  display: none; }

img.media-element {
  width: 400px; }

@media screen and (min-width: 541px) {
  .line.subline {
    width: 150px; } }

@media screen and (min-width: 641px) {
  .label-dateBox {
    text-align: left;
    font-size: 14px; }
  .dateBox {
    width: 47%;
    float: left; }
    .dateBox .dateTimeField {
      margin: 0;
      width: 50%;
      float: left; }
      .dateBox .dateTimeField:first-child {
        padding-right: 15px; }
  .bis {
    float: left;
    width: 6%;
    font-size: 18px;
    padding: 8px;
    line-height: 22px; } }

@media screen and (min-width: 641px) {
  .node-werbung .content .gutKat-box {
    text-align: left; }
  .l-footer {
    font-size: 14px; }
    .l-footer .footer-flex-container {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
      justify-content: center;
      -webkit-flex-direction: row;
      flex-direction: row; }
      .l-footer .footer-flex-container .flex-item {
        width: 33.3333%; }
        .l-footer .footer-flex-container .flex-item.first {
          -webkit-order: 0;
          order: 0; }
        .l-footer .footer-flex-container .flex-item.second {
          -webkit-order: 0;
          order: 0; }
    .l-footer ul.menu {
      max-width: 320px; } }

@media screen and (min-width: 768px) {
  .flex-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-color: #f6f6f6; }
    .flex-container .fahrzeugbilder, .flex-container .fahrzeugbeschreibung {
      width: 50%; }
  .teaser-reise {
    margin: 15px 15px; }
  .no-slider-empfehlung .node-reise .teaser-reise {
    margin: 15px 15px; } }

@media screen and (min-width: 52.5625em) {
  .show-for-medium-up {
    display: block !important; }
  .show-for-medium-down {
    display: none !important; }
  .show-for-medium-down-as-inline-block {
    display: none !important; }
  .show-for-medium-up-as-inline-block {
    display: inline-block !important; }
  body {
    font-size: 18px;
    padding-top: 128px; }
  .field-name-field-reiseart {
    font-size: 18px; }
  .block-bean {
    width: 100%;
    padding: 0; }
    .block-bean:last-child {
      padding: 0; }
    .block-bean .bean-gut-kat .gutKat-box {
      width: 100%; }
  .section-heading {
    font-size: 1.875rem;
    font-weight: 700; }
  .section-werbung.paragraph {
    background-color: #344a71;
    padding: 40px 0 0; }
    .section-werbung.paragraph .node-werbung {
      width: 50%;
      padding-right: 15px; }
      .section-werbung.paragraph .node-werbung:last-child {
        padding-left: 15px;
        padding-right: 0; }
      .section-werbung.paragraph .node-werbung .bean-gut-kat {
        margin: 0 0 40px 0; }
        .section-werbung.paragraph .node-werbung .bean-gut-kat .gutKat-box {
          height: 260px; }
  .termin .field-reiseart {
    color: #ffffff;
    font-size: 18px; }
  header .top-header .mail {
    margin-left: 50px;
    display: inline-block; }
  .top-bar .top-bar-right {
    text-align: right; }
    .top-bar .top-bar-right .content > .menu {
      position: relative;
      display: inline-block;
      margin-top: 13px; }
      .top-bar .top-bar-right .content > .menu > li {
        position: relative; }
        .top-bar .top-bar-right .content > .menu > li > a {
          color: #ffffff;
          display: inline-block;
          transition: all 0.6s;
          padding: 11px 3px; }
          .top-bar .top-bar-right .content > .menu > li > a.active {
            font-weight: 700;
            color: #fcc500; }
        .top-bar .top-bar-right .content > .menu > li:hover a {
          color: #fcc500; }
        .top-bar .top-bar-right .content > .menu > li:hover .menu {
          visibility: visible;
          height: auto;
          display: block;
          opacity: 1;
          top: 50px; }
        .top-bar .top-bar-right .content > .menu > li .menu {
          position: absolute;
          top: 70px;
          right: 0;
          background: #ffffff;
          visibility: hidden;
          opacity: 0;
          transition: all 0.6s;
          box-shadow: 0 0 40px 0 rgba(58, 57, 57, 0.25); }
          .top-bar .top-bar-right .content > .menu > li .menu li {
            display: block;
            white-space: nowrap;
            padding: 4px 0 0;
            margin: 0; }
            .top-bar .top-bar-right .content > .menu > li .menu li a {
              color: #121211;
              transition: all 0.6s;
              opacity: 0.6;
              border-bottom: solid 1px rgba(28, 28, 28, 0.1); }
              .top-bar .top-bar-right .content > .menu > li .menu li a:before {
                content: ''; }
              .top-bar .top-bar-right .content > .menu > li .menu li a.active {
                opacity: 1; }
            .top-bar .top-bar-right .content > .menu > li .menu li:hover a {
              opacity: 1; }
          .top-bar .top-bar-right .content > .menu > li .menu:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            transform: rotate(90deg);
            top: -20px;
            right: 45px;
            border-style: solid;
            border-width: 20px 20px 20px 0;
            border-color: transparent #ffffff transparent transparent; }
  .top-bar .top-bar-left {
    width: auto; }
  .menu-overlay {
    display: none; }
  .item .owl-text {
    width: 480px; }
  .l-footer {
    font-size: 18px; } }

@media screen and (min-width: 990px) {
  .top-bar .top-bar-right .content > .menu > li > a {
    padding: 11px 16px; } }

.c-cib {
  position: fixed;
  bottom: -300px;
  width: 100%;
  background: #ffffff;
  color: #333333;
  z-index: 100000;
  transition: all .7s ease-out;
  font-size: 14px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4); }
  .c-cib a {
    text-decoration: underline; }
  .c-cib .cib-button-wrapper {
    text-align: center;
    flex: 0 0 100%;
    max-width: 100%; }
  .c-cib .cib-button {
    cursor: pointer;
    margin: 0 20px;
    font-weight: 700; }
    .c-cib .cib-button:hover {
      background: #e3051b;
      color: #ffffff; }
  .c-cib .tx {
    width: calc(100% - 50px);
    margin-bottom: 10px;
    line-height: 1.5;
    flex: 0 0 100%;
    max-width: 100%; }
  .c-cib.open {
    padding: 16px 0;
    bottom: 0; }
  .c-cib .flex-container {
    display: flex;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -ms-box-orient: horizontal;
    -webkit-box-direction: normal;
    background: white; }
    .c-cib .flex-container.align-middle {
      -webkit-box-align: center;
      align-items: center; }

@media screen and (min-width: 641px) {
  .c-cib .tx {
    flex: 1 0 0%; }
  .c-cib .cib-button-wrapper {
    flex: 0 0 25%;
    max-width: 25%; } }

.copyright {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 3px 8px 3px 20px;
  font-size: 11px;
  color: white;
  font-style: normal;
  border-radius: 10px;
  background: #344a70;
  mix-blend-mode: hard-light;
  margin: 5px; }
  .copyright.copy-reise, .copyright.copy-buehne {
    display: none; }
  .copyright:before {
    content: '©';
    position: absolute;
    left: 7px;
    top: 4px;
    line-height: 1.2; }
